// @flow

import React, { useContext, useState, useEffect } from 'react';
import type { Node } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { MediaQuery } from '@riseart/common';
import { OverlayBar } from '@riseart/layout';
import { cookies as CONFIG_COOKIES, storage as STORAGE_CONFIG } from 'Config';
import { analytics as ENUM_ANALYTICS } from 'Enum';
import { SSRContext } from 'shared_data/providers/ssr/Context';
import { GridNoResults } from 'shared_components/arts/containers/grid/NoResults';
import { Cookies } from 'shared_services/riseart/utils/Cookies/Cookies';
import { StorageConsumer } from 'shared_services/riseart/storage/Consumer';
import { ArtSlider } from 'shared_components/arts/slider/Slider';
import { recentArtworksMapper } from 'shared_services/riseart/utils/artDataUtils';
import { selectGuiRecentArtWidget } from 'shared_services/redux/selectors/gui';
import { guiUpdateRecentArtWidget } from 'shared_services/redux/actions/application/gui';

import { raScreenSm } from '@riseart/antd-provider/dist/website/variables.less';

type ComponentProps = {
  showOnPage: boolean,
};

type Props = {
  ...ComponentProps,
  items: Array<Object>,
};

/**
 * toggleRecentArtworksWidget
 *
 * @param {boolean} open
 */
export function toggleRecentArtworksWidget(
  opened: boolean,
  dispatch: Function,
  showRecentWidgetOnPage?: boolean = false,
) {
  dispatch(
    guiUpdateRecentArtWidget({
      opened,
      explicitlyOpenWidgetOnPage: opened && !showRecentWidgetOnPage,
    }),
  );
}

/**
 * RecentArtworksWidgetComponent
 *
 * @param {Props} props
 * @returns {Node}
 */
function RecentArtworksWidgetComponent({ showOnPage, items }: Props): Node {
  const hasItems = items ? !!items.length : false;
  const dispatch = useDispatch();
  const { opened: isOpenedInState, explicitlyOpenWidgetOnPage = false } =
    useSelector(selectGuiRecentArtWidget, shallowEqual) || {};

  // Check if initial items exists for case when hasItems but no cookie set
  if (RecentArtworksWidgetComponent.hasInitialItems === null) {
    RecentArtworksWidgetComponent.hasInitialItems = !!hasItems;
  }

  const { recentArtworksWidget: RECENT_ARTWORKS_COOKIE } = CONFIG_COOKIES;
  const cookieValue = Cookies.get(RECENT_ARTWORKS_COOKIE.name);
  const widgetCookie = cookieValue ? JSON.parse(cookieValue) : {};
  const [isBarVisible, setIsBarVisible] = useState(
    widgetCookie.fs === false && RecentArtworksWidgetComponent.hasInitialItems,
  );
  const [showBar, setShowBar] = useState(
    (widgetCookie.closed === undefined || widgetCookie.closed === false) &&
      isBarVisible &&
      !!hasItems,
  );
  const cookieNotSet = widgetCookie.fs === undefined;
  // Always check if last added item is no earlier than the minutes set in `userInactivityMins` config property
  const userInactivity =
    widgetCookie.fs === true &&
    hasItems &&
    items[0].dateAddedInStorage <
      Date.now() - STORAGE_CONFIG.recentArtworksWidget.userInactivityMins * 60 * 1000;

  if (cookieNotSet || userInactivity) {
    const isFirstSession = (cookieNotSet && !hasItems) || false;

    Cookies.set(
      RECENT_ARTWORKS_COOKIE.name,
      JSON.stringify({
        ...widgetCookie,
        closed: false,
        fs: isFirstSession,
      }),
      RECENT_ARTWORKS_COOKIE,
    );

    if (!isFirstSession) {
      // Allow bar to be visible only after firstSession has expired
      setIsBarVisible(true);
    }

    if (!showBar) {
      setShowBar(true);
    }
  }

  useEffect(() => {
    // Call Redux action creator for open event only once per app launch
    if (showOnPage && showBar && isBarVisible && !RecentArtworksWidgetComponent.hasInit) {
      RecentArtworksWidgetComponent.hasInit = true;

      dispatch(guiUpdateRecentArtWidget({ opened: true, explicitlyOpenWidgetOnPage: false }));
    }
    return () => {};
  }, [dispatch, showOnPage, showBar, isBarVisible]);

  /**
   * handleClose
   */
  function handleClose() {
    dispatch(guiUpdateRecentArtWidget({ opened: false, explicitlyOpenWidgetOnPage: false }));
  }

  return (
    <OverlayBar
      controlled={false}
      opened={(showOnPage && isOpenedInState) || (!showOnPage && explicitlyOpenWidgetOnPage)}
      title={
        <MediaQuery minWidth={raScreenSm}>
          {(isTablet) => (
            <FormattedMessage
              id={`components.layout.recentlyViewed.${isTablet ? 'long' : 'short'}`}
            >
              {(title: string) => title}
            </FormattedMessage>
          )}
        </MediaQuery>
      }
      onClose={handleClose}
    >
      {hasItems ? (
        <ArtSlider
          tracking={{
            enabled: true,
            enableInitial: !RecentArtworksWidgetComponent.hasInit,
            enableVisibility: false,
            enableClick: true,
            name: ENUM_ANALYTICS.list.name.RECENT_ART_WIDGET,
          }}
          items={items}
          columnCount="7"
          displayStyle="2"
        />
      ) : (
        <GridNoResults>
          <FormattedMessage id="components.art.gridNoArtworksDefault">
            {(noItemsText: string) => noItemsText}
          </FormattedMessage>
        </GridNoResults>
      )}
    </OverlayBar>
  );
}

RecentArtworksWidgetComponent.hasInit = false;
RecentArtworksWidgetComponent.hasInitialItems = null;

/**
 * RecentArtworksWidget
 *
 * @param {ComponentProps} props
 * @returns {Node}
 */
export function RecentArtworksWidget({ showOnPage }: ComponentProps): Node {
  const { isSSR } = useContext(SSRContext);

  return !isSSR ? (
    <StorageConsumer
      storageType={STORAGE_CONFIG.recentArtworksWidget.type}
      storageKey={STORAGE_CONFIG.recentArtworksWidget.key}
      dataMapper={recentArtworksMapper(STORAGE_CONFIG.recentArtworksWidget.timeLimitDays)}
    >
      {(recentlyViewedArtworks = []) => {
        const items =
          recentlyViewedArtworks &&
          recentlyViewedArtworks.slice(0, STORAGE_CONFIG.recentArtworksWidget.limit);

        return <RecentArtworksWidgetComponent showOnPage={showOnPage} items={items} />;
      }}
    </StorageConsumer>
  ) : null;
}
