// @flow

import React, { useContext } from 'react';
import { PreloaderRipple } from '@riseart/common';
import { SSRContext } from 'shared_data/providers/ssr/Context';
import { WithOffsetHeight } from 'shared_hocs/gui/withOffsetHeight';

/**
 * IsomorphicRipple
 * Ripple loader wrapped around SSRConsumer
 * on server it renders only the Ripple children, and no loader
 * @param {Object} props
 */
export const IsomorphicRipple = ({
  children,
  customStyles,
  ...restProps
}: Object): React$Element<Object> => {
  const { isSSR } = useContext(SSRContext);

  return (
    <WithOffsetHeight>
      {({ totalHeight }) => (
        <PreloaderRipple
          customStyles={{
            ...(!isSSR && restProps.isFullScreen
              ? { height: `calc(100vh - ${totalHeight}px)` }
              : null),
            ...customStyles,
          }}
          {...restProps}
          {...(isSSR ? { isActive: false } : null)}
        >
          {children}
        </PreloaderRipple>
      )}
    </WithOffsetHeight>
  );
};
