// @flow

import React from 'react';
import type { Node } from 'react';
import { useIntl } from 'react-intl';
import { GiftIcon } from '@riseart/icons';
import { MediaQuery } from '@riseart/common';

import { raScreenLg } from '@riseart/antd-provider/dist/website/variables.less';
import {
  xmaxCls,
  xmaxIconCls,
  xmaxContentCls,
} from 'shared_components/messages/christmas/styles.less';

/**
 * ChristmasGift
 *
 * @param {Props} props
 * @returns {Node}
 */
export const ChristmasGift = (): Node => {
  const intl = useIntl();

  return (
    <div className={xmaxCls}>
      <div className={xmaxIconCls}>
        <GiftIcon />
      </div>
      <MediaQuery minWidth={raScreenLg}>
        {(isLarge) => (
          <div className={xmaxContentCls}>
            {intl.formatMessage({ id: 'messages.application.christmasGift.line1' })}
            {isLarge ? ' ' : <br />}
            <span
              className={xmaxContentCls}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'messages.application.christmasGift.line2' }),
              }}
            />
          </div>
        )}
      </MediaQuery>
    </div>
  );
};
